import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import RightArrow from "../assets/Icons/go.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import BannerBgImage from "../assets/Images/Code-audit-UX-audit-banner-image.png";
import MiniImgBnrBgImg from "../assets/Images/contact_us_bg.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import MiniImageBannerBgImg12 from "../assets/Images/HowWeDoIt/who_we_work_with.png";
import ogimage from "../assets/Images/ogImages/OG_Code-audit-UX.png";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
import DataService from "../services/api/data_services";
// import RedLine from "../components/RedLine";
import { BASE_SITE_URL } from "../shared/constants";
//BlurCardList
import BlurCardList from "../templates/BlurCardList";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
//Info View
import Infoview from "../templates/InfoView";
import Megaview from "../templates/MegaView";
//Mini Image Banner
import MiniImageBanner from "../templates/MiniImageBanner";
import MiniImageBanner1 from "../templates/MiniImageBanner1";
//Miniview
import Miniview from "../templates/MiniView";
// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import VerticalLinearStepper from "../templates/VerticalStepper";

const getStepper = () => {
  return [
    "Code quality",
    "Software architecture",
    "Coding best practices",
    "Code security review",
    <>Non-functional requirements</>,
    "Object-oriented analysis and design principles (SOLID)",
    "List of recommendations",
  ];
};
const getStepper1 = () => {
  return [
    "Business and user objectives",
    "Metrics and customer data",
    "Usability guidelines compliance",
    "Design evaluation",
    "List of recommendations",
  ];
};
const getStepper2 = () => {
  return [
    "Front-end (Angular, React.js, Vue.js, Ember.js)",
    "Back-end (Ruby on Rails, Python, PHP, .NET core)",
  ];
};
const getStepper3 = () => {
  return [
    "Automated security check",
    "Manual security check",
    " Manual code review",
    "Automated static code review",
    "Business analysis",
    "Automated scalability tests",
    " Report and presentation",
  ];
};
const getStepper4 = () => {
  return [
    "Product Research and analysis",
    "Review of business and user objectives",
    " Review of conversion metrics and traffic data",
    " Manual user journey review",
    "Checklist of UX Laws and Principles",
    "Usability and UI check",
    " Report and presentation",
  ];
};

const CodeUxAudit = () => {
  const stepper = getStepper();
  const stepper1 = getStepper1();
  const stepper2 = getStepper2();
  const stepper3 = getStepper3();
  const stepper4 = getStepper4();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };
  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=PD - Code %26 UI/UX Audit"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=PD - Code %26 UI/UX Audit&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${limit}&page=${page}&type=Client Story&service_type=PD - Code %26 UI/UX Audit&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg12,
    tag: "h2",
    tooltip: "yes",
    tip: "TAKE A LOOK",
    altTxt: "Who we work with",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    underlineClass: "black-line",
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    // bnrImgTitle: "Go Digital For More!",
    bnrImgTitle: (
      <div>
        Code Audit <br /> & UX Audit
      </div>
    ),
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };

  /**
   * Infoview
   */

  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        Help your Digital Product Thrive <br />
        <span className="red-line3letter">wit</span>h Code & UX Audit
      </div>
    ),
    tag: "h2",
    InfoviewContent: (
      <>
        Identify security flaws by auditing your software’s source code and UX.
        This step helps you avoid any technical problems and keep your digital
        product in good condition. At Digiryte, we want to ensure that your
        product is perfect for the market. Our expert team will help you
        identify the problem areas and create actionable plans to help improve
        your product and empower your business.
      </>
    ),
  };

  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  const MiniImageBanner3Data = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us ",
    HoverLinkContent: "Contact us",
    PlainContent: "to audit your product",
    route: "/contact-us",
  };
  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  const miniviewDataCaseStudy = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  return (
    <Layout
      seo={{
        title: "What we do: Code and UX Audit | Digiryte",
        ogtitle: "What we do: Code and UX Audit | Digiryte",
        description:
          "Identify security flaws by auditing your software’s source code and UX. This helps avoiding technical problems and keeps the digital product in good condition.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/code-ux-audit`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="mt-70 body-container">
        <Grid container className="mt-30  ttc" direction="row" spacing={3}>
          <Grid item md={6}>
            <h3 className="h3-27-header">
              <span className="red-line3letter">UX a</span>udit scope
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper1} />
            <div className="mt-50"></div>
            <h3 className="h3-27-header">
              <span className="red-line3letter">We a</span>udit the following
              technologies:
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper2} />
          </Grid>
          <Grid item md={6}>
            <h3 className="h3-27-header">
              <span className="red-line3letter">Cod</span>e audit scope
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper} />
          </Grid>
        </Grid>
      </div>
      <div className="mt-50">
        <MiniImageBanner miniImageBannerData={MiniImageBanner3Data} />
      </div>
      <div className="body-container">
        <Grid container className="mt-70  ttc" direction="row" spacing={3}>
          <Grid item md={6}>
            <h3 className="h3-27-header">
              <span className="red-line3letter">Cod</span>e audit process
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper3} />
          </Grid>
          <Grid item md={6}>
            <h3 className="h3-27-header">
              <span className="red-line3letter">UX a</span>udit process
            </h3>
            <div className="mt-30"></div>
            <VerticalLinearStepper stepper={stepper4} />
          </Grid>
        </Grid>
      </div>
      <div className="mt-50">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h2 className="h2-header">
              <span className="red-line3letter">Cli</span>ent Stories
            </h2>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={clientStoryData}
              miniviewData2={miniviewDataCaseStudy}
              total={clientStoryData.length}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          className="mt-60"
          role="presentation"
          onClick={() => {
            setPage(page === 1 ? page + 2 : page + 1);
            setLimit(3);
          }}
          style={{ display: "inline-block" }}
        >
          {clientStoryRawData.has_more && (
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="Load More"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="Load More"
            />
          )}
        </div>
      </div>
      <div className="body-container">
        {" "}
        {megaviewData.data && megaviewData.data.length > 0 && (
          <Megaview MegaviewData={megaviewData.data} />
        )}
      </div>

      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="body-container mt-70">
        <h2 className="title-header">
          <span className="red-line3letter">Oth</span>er Services
        </h2>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default CodeUxAudit;
